<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Blade Variables</h4>
          </template>
          <template v-slot:headerAction>

          </template>
          <template v-slot:body >
          <div>
            <table class="table table-borderless table-striped table-earning">
              <thead>
                <tr style="background-color: #553b9b61">
                  <th width="50%">Group</th>
                  <th width="25%">Variable Name</th>
                  <th width="25%">Variable Value</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(group, index) in blade_variables">
                  <tr :key="index" style="background-color: #b4bfbc;">
                    <td>{{ formatGroupName(index) }}</td>
                    <td></td>
                    <td><i :class="isRowOpen(index) ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down'"
                        class="pointcursor float-right" :id="'toggle' + index" @click="toggleRow(index)"></i></td>
                  </tr>
                  <template v-for="item in group">
                    <tr v-if="isRowOpen(index)" :key="item.id">
                      <td width="50%"></td>
                      <td width="25%">{{ item.name }}</td>
                      <td width="25%">{{ item.value }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      blade_variables: [],
      openRows: [],
    };
  },
  methods: {
    toggleRow(index) {
      const rowIdx = this.openRows.indexOf(index);
      if (rowIdx === -1) {
        this.openRows.push(index);
      } else {
        this.openRows.splice(rowIdx, 1);
      }
    },
    isRowOpen(index) {
      return this.openRows.includes(index);
    },
    formatGroupName(group) {
      return group
        .replace(/_/g, " ")
        .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
    },
    getVariables() {
      this.axios
        .get("/blade-variables")
        .then((response) => {
          this.blade_variables = response.data.data;
        })
        .catch((error) => {
        });
    },
  },
  mounted() {
    this.getVariables();
  },
};
</script>
<style>
.pointcursor {
  cursor: pointer;
}
</style>
